import { FormikErrors, FormikTouched, useFormik } from 'formik';
import * as Yup from 'yup';
import {
  addOrganization,
  updateOrganization,
} from '../../../../store/orgs/orgs.actions';
import { Features, Organization } from '../../../../types';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { updateStripeCustomerId } from '../../../../store/orgs/orgs.thunks';

export type AddOrgForm = {
  email?: string;
  orgName?: string;
  url?: string;
  orgSeats?: number | string;
  acquisitionSource?: string | null;
  stripeCustomerId?: string | null;
  emailAnalyzer?: boolean;
  emailSimulator?: boolean;
  inlineMenu?: boolean;
  myEngagements?: boolean;
  myInsights?: boolean;
  myAnalytics?: boolean;
  teams?: boolean;
  teamAnalytics?: boolean;
  eventNotifications?: boolean;
  xRayAnalysis?: boolean;
  xRaySimulation?: boolean;
  webhooks?: boolean;
  soundwave?: boolean;
};

type UseOrgFormParams = {
  onClose: () => void;
  isAdd: boolean;
  org?: Organization | null;
  isMyOrgEdit?: boolean;
};

type UseOrgFormReturn = {
  handleCancel: () => void;
  errors: FormikErrors<AddOrgForm>;
  handleChange: (e: string | React.ChangeEvent<HTMLInputElement>) => void;
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  setFieldValue: (
    field: string,
    value: boolean,
    shouldValidate?: boolean
  ) => void;
  touched: FormikTouched<AddOrgForm>;
  values: AddOrgForm;
  isErrorMsg: boolean;
  isOrgCreated: boolean;
  isLoading: boolean;
};

export const useOrgForm = ({
  isAdd,
  onClose,
  org,
}: UseOrgFormParams): UseOrgFormReturn => {
  const user = useAppSelector((state) => state.auth.user);
  const organization = useAppSelector((state) => state.auth.org);
  const isErrorMsg = useAppSelector((state) => state.orgs.isErrorMsg);
  const isOrgCreated = useAppSelector((state) => state.orgs.isOrgCreated);
  const currentOrg = useAppSelector((state) => state.orgs.currentOrg);
  const status = useAppSelector((state) => state.orgs.status);
  const stripeCustomerId = useAppSelector(
    (state) => state.orgsNew.stripeCustomerId
  );

  const isUpdateStripeCustomerIdLoading = useAppSelector(
    (state) => state.orgsNew.isLoading.updateStripeCustomerId
  );

  const { editLoading } = status;

  const dispatch = useAppDispatch();

  const validationSchema = Yup.object().shape({
    orgName: Yup.string().required('This field is required'),
    url: Yup.string().url().required('This field is required'),
    email: Yup.string().email().trim().required('This field is required'),
    location: Yup.string().nullable(),
    orgSeats: Yup.number().typeError('Must be only digits'),
    soundwave: Yup.string(),
  });

  const handleSubmit = async (values: AddOrgForm) => {
    const checkBooleansDiff =
      (currentOrg?.enabledFeatures &&
        JSON.stringify(Object.values(values).slice(5)) !==
          JSON.stringify(Object.values(currentOrg.enabledFeatures))) ||
      false;

    const valuesDiffCheck =
      (values.url?.length && values.url !== currentOrg?.url) ||
      (values.orgSeats?.toString().length &&
        +values.orgSeats !== currentOrg?.maxUsersCount) ||
      values.acquisitionSource !== currentOrg?.acquisitionSource ||
      checkBooleansDiff;

    const isStripeCustomerIdUpdated =
      stripeCustomerId !== (values.stripeCustomerId || null);

    try {
      if (isStripeCustomerIdUpdated && currentOrg && !isAdd) {
        await dispatch(
          updateStripeCustomerId({
            orgId: currentOrg.orgId,
            stripeCustomerId: values.stripeCustomerId || null,
          })
        ).unwrap();
      }
    } catch (e) {
      return;
    }

    if (isAdd) {
      const valuesToCreateOrg = {
        orgName: values.orgName,
        email: values.email?.trim(),
        url: values.url,
        maxUsersCount: values?.orgSeats?.toString().replace('+', '') || '5',
        acquisitionSource: values.acquisitionSource,
        stripeCustomerId: values.stripeCustomerId || null,
        features: [
          {
            feature: Features.EMAIL_ANALYZER,
            enabled: values.emailAnalyzer || false,
          },
          {
            feature: Features.EMAIL_SIMULATOR,
            enabled: values.emailSimulator || false,
          },
          {
            feature: Features.INLINE_MENU,
            enabled: values.inlineMenu || false,
          },
          {
            feature: Features.MY_ENGAGEMENTS,
            enabled: values.myEngagements || false,
          },
          {
            feature: Features.MY_INSIGHTS,
            enabled: values.myInsights || false,
          },
          {
            feature: Features.MY_ANALYTICS,
            enabled: values.myAnalytics || false,
          },
          {
            feature: Features.TEAMS,
            enabled: values.teams || false,
          },
          {
            feature: Features.TEAM_ANALYTICS,
            enabled: values.teamAnalytics || false,
          },
          {
            feature: Features.EVENT_NOTIFICATIONS,
            enabled: values.eventNotifications || false,
          },
          {
            feature: Features.X_RAY_ANALYSIS,
            enabled: values.xRayAnalysis || false,
          },
          {
            feature: Features.X_RAY_SIMULATION,
            enabled: values.xRaySimulation || false,
          },
          {
            feature: Features.WEBHOOKS,
            enabled: values.webhooks || false,
          },
          {
            feature: Features.SOUNDWAVE,
            enabled: values.soundwave || false,
          },
        ],
      };
      dispatch(
        addOrganization(
          valuesToCreateOrg,
          user?.orgId || '',
          user?.userId || ''
        )
      );
    } else if (valuesDiffCheck) {
      dispatch(
        updateOrganization(
          {
            url: values.url,
            maxUsersCount: values?.orgSeats?.toString().replace('+', ''),
            acquisitionSource: values.acquisitionSource,
            features: [
              {
                feature: Features.EMAIL_ANALYZER,
                enabled: values.emailAnalyzer || false,
              },
              {
                feature: Features.EMAIL_SIMULATOR,
                enabled: values.emailSimulator || false,
              },
              {
                feature: Features.INLINE_MENU,
                enabled: values.inlineMenu || false,
              },
              {
                feature: Features.MY_ENGAGEMENTS,
                enabled: values.myEngagements || false,
              },
              {
                feature: Features.MY_INSIGHTS,
                enabled: values.myInsights || false,
              },
              {
                feature: Features.MY_ANALYTICS,
                enabled: values.myAnalytics || false,
              },
              {
                feature: Features.TEAMS,
                enabled: values.teams || false,
              },
              {
                feature: Features.TEAM_ANALYTICS,
                enabled: values.teamAnalytics || false,
              },
              {
                feature: Features.EVENT_NOTIFICATIONS,
                enabled: values.eventNotifications || false,
              },
              {
                feature: Features.X_RAY_ANALYSIS,
                enabled: values.xRayAnalysis || false,
              },
              {
                feature: Features.X_RAY_SIMULATION,
                enabled: values.xRaySimulation || false,
              },
              {
                feature: Features.WEBHOOKS,
                enabled: values.webhooks || false,
              },
              {
                feature: Features.SOUNDWAVE,
                enabled: values.soundwave || false,
              },
            ],
          },
          org?.orgId || '',
          org?.orgId === organization?.orgId
        )
      );
      onClose();
    }
  };

  const formik = useFormik<AddOrgForm>({
    initialValues: {
      orgName: isAdd ? '' : org?.name || '',
      url: isAdd ? '' : org?.url || '',
      email: isAdd ? '' : org?.primaryEmail || '',
      orgSeats: isAdd ? '' : org?.maxUsersCount.toString() || '',
      acquisitionSource: isAdd ? '' : org?.acquisitionSource || '',
      stripeCustomerId: isAdd ? '' : stripeCustomerId || '',
      emailAnalyzer: isAdd
        ? false
        : org?.enabledFeatures?.emailAnalyzer || false,
      emailSimulator: isAdd ? false : org?.enabledFeatures?.simulator || false,
      inlineMenu: isAdd ? false : org?.enabledFeatures?.inlineMenu || false,
      myEngagements: isAdd
        ? false
        : org?.enabledFeatures?.myEngagements || false,
      myInsights: isAdd ? false : org?.enabledFeatures?.myInsights || false,
      myAnalytics: isAdd ? false : org?.enabledFeatures?.myAnalytics || false,
      teams: isAdd ? false : org?.enabledFeatures?.teams || false,
      teamAnalytics: isAdd
        ? false
        : org?.enabledFeatures?.teamAnalytics || false,
      eventNotifications: isAdd
        ? false
        : org?.enabledFeatures?.eventNotifications || false,
      xRayAnalysis: isAdd ? false : org?.enabledFeatures?.xRayAnalysis || false,
      xRaySimulation: isAdd
        ? false
        : org?.enabledFeatures?.xRaySimulation || false,
      webhooks: isAdd ? false : org?.enabledFeatures?.webhooks || false,
      soundwave: isAdd ? false : org?.enabledFeatures?.soundwave || false,
    },
    onSubmit: handleSubmit,
    validationSchema,
    enableReinitialize: true,
  });

  const handleCancel = () => {
    formik.resetForm();
    onClose();
  };

  return {
    ...formik,
    handleCancel,
    isErrorMsg,
    isOrgCreated,
    isLoading: editLoading || isUpdateStripeCustomerIdLoading,
  };
};
